import { dbConstants as dc } from '../constants';

export const dbConnected = db => {
  return {
    type: dc.DB_CONNECTED,
    db
  };
};

export const dbFailed = () => {
  return {
    type: dc.DB_FAILED
  };
};

export const dbCallsUpdated = (theUpdate) => {
  return {
    type: dc.DB_CALLS_UPDATED,
    theUpdate
  };
};
