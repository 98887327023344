import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";

import md5 from "md5";

import {
  appSomethingIsLoading,
  appAllLoaded,
  appSetSearchOfficesOnly,
} from '../actions';

import mongoConfig from "../mongo";

const useDropdownOffices = (selectedOfficeStatus, selectedGroup) => {
  const dispatch = useDispatch();
  const mongoUser = useSelector( (state) => state.auth.user );
  const [officesList, setOfficesList] = useState(null);

  // display all offices for all users except admin
  // only admin can sort by office status new/active etc..
  // if ( mongoUser.customData.role !== 'admin') {
  //   selectedOfficeStatus = 'all';
  // }

  useEffect(() => {
    let mounted = true;

    const storageQuery = md5('officesDropdownDataV8-' + mongoUser.customData.userID+'-' + mongoUser.customData.company + '-' + selectedGroup+ '-' + selectedOfficeStatus);
    const storageOfficesIdsQuery = md5('officesIdsDropdownDataV8-' + mongoUser.customData.userID+'-' + mongoUser.customData.company + '-' + selectedGroup+ '-' + selectedOfficeStatus);

    // try to load data from cache
    // if ( sessionStorage.getItem(storageQuery)  ) {
    //   dispatch(appSetSearchOfficesOnly(JSON.parse(sessionStorage.getItem(storageOfficesIdsQuery))));
    //   if ( mounted ) {
    //     setOfficesList(JSON.parse(sessionStorage.getItem(storageQuery)));
    //   }
    // } else
    {
      dispatch(appSomethingIsLoading());

      let officesFilter = {
        groupID: selectedGroup || {$exists: true}
      };

      if ( selectedOfficeStatus && selectedOfficeStatus !== 'all' ) {
        officesFilter['officeStatus'] = selectedOfficeStatus;
      }
      mongoUser
      .mongoClient(mongoConfig.realmServiceName)
      .db(mongoConfig.databaseName)
      .collection('offices')
      .find(officesFilter,
            {sort: { 'name': 1}, projection: { _id: 1, name: 1}}
          )
      .then(offices => {
        // console.log(offices);
        dispatch(appAllLoaded());
        let renamedOffices = offices.map((e) => ( { id: e._id, label: e.name }) );
        let officesIDs = offices.map((e) => ( e._id) );

        sessionStorage.setItem(storageOfficesIdsQuery, JSON.stringify(officesIDs));

        if ( selectedOfficeStatus && selectedOfficeStatus !== 'all' ) {
          dispatch(appSetSearchOfficesOnly(officesIDs));
        } else {
          dispatch(appSetSearchOfficesOnly(null));
        }

        if ( mounted ) {
          setOfficesList(renamedOffices);
        }
        sessionStorage.setItem(storageQuery, JSON.stringify(renamedOffices));
      });
    }

    // clean up
    return () => {
      mounted = false;
    }

  }, [dispatch, mongoUser, selectedGroup, selectedOfficeStatus]);
  return officesList;
}

export default useDropdownOffices;
