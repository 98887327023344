import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";

import { Container, Box, Typography, Link } from '@mui/material';

import { doConfirmUserEmail } from "../utils";

export const ConfirmEmailPage = () => {
  const query = new URLSearchParams(useLocation().search);
  const [token] = useState(query.get("token") || null);
  const [tokenId] = useState(query.get("tokenId") || null);

  const [wasConfirmed, setWasConfirmed] = useState(null);

  useEffect(() => {
    if ( token !== null && tokenId !== null) {
      doConfirmUserEmail(token, tokenId).then((result) => {
        setWasConfirmed(result);
      });
    }
  }, [token, tokenId]);

  return (
    <Container maxWidth="xs">
      <Box display="flex" height={1} >
        <Box width={1} m="auto" sx={{textAlign: 'center'}}>
          { (wasConfirmed === true) &&
            <Typography>Hooray, Ring Analytics registration completed. <br/> <Link href="/login">Login</Link></Typography>
          }
          { (wasConfirmed === false) &&
            <Typography>Hooray, Ring Analytics registration completed. <br/> <Link href="/login">Login</Link></Typography>
          }
          { (wasConfirmed === null) &&
            <Typography>Email confirmation in progress...</Typography>
          }

        </Box>
      </Box>
    </Container>
  );
};
