import { red, teal, grey } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  palette: {
    chat: {
      background: '#ffffff',
      heading: '#00897b',
      headingText: '#ffffff',
      noteBubbleBg: '#F3F4F6',
    },
    keywords: {
      booked: {
        tagTimeIssue: 'rgb(228, 26, 28)',
        tagSpecialtyIssue: 'rgb(55, 126, 184)',
        tagInsuranceIssue: 'rgb(77, 175, 74)',
        tagPriceIssue: 'rgb(255, 127, 0)',
        tagMedicaid: '#CAB2D6',
        tagMedicare: '#F781BF',
      },
      notBooked: {
        tagAvailabilityIssue: 'rgb(228, 26, 28)',
        tagCancellationsIssue: 'rgb(55, 126, 184)',
        tagInsuranceIssue: 'rgb(77, 175, 74)',
        tagPriceIssue: 'rgb(255, 127, 0)',
        tagMedicaid: '#CAB2D6',
        tagMedicare: '#F781BF',
        tagCallBackIssue: '#5c6bc0',
        tagSpecialtyIssue: '#26a69a',
      },
      other: {
        tagAppointmentIssue: 'rgb(228, 26, 28)',
        tagGlassesAndContactsIssue: 'rgb(55, 126, 184)',
        tagInsuranceIssue: 'rgb(77, 175, 74)',
        tagDirectionsIssue: 'rgb(255, 127, 0)',
        tagCallBackIssue: '#5c6bc0',
        tagSpecialtyIssue: '#26a69a',
      }
    },
    sentiment: {
      positive: '#38761D',
      neutral: '#757575',
      negative: '#CC0000',
      tip: '#FFA500',
    },
    player: {
      progress: '#EBEBEB',
      filled: '#B1CEE2',
      downloaded: '#E6EEF5',
      indicator: '#ffffff',
      indicatorborder: '#486db4',
      transcriptbg: '#ffffff',
    },
    sidebarmenu: {
      selected: '#EBEBEB',
      hover: '#F5F5F5',
    },
    primary: {
      main: '#486db4',
      listHover: '#F7FAFC',
      greyBorder: '#e0e0e0',
      bgHighlight: '#FFFDF6',
      yellowhighlight: '#FFFAE0',
      greenhighlight: '#B0D4AB',
      lightGrey: grey[200],
    },
    secondary: {
      main: '#486db4',
    },
    reds: {
      main: red[400],
    },
    greens: {
      main: teal[400],
    },
    topbar: {
      text: '#ffffff',
    },
    sidebar: {
      link: 'rgba(0, 0, 0, 0.87)',
    },
    error: {
      main: red.A400,
    },
    success: {
      main: teal[600],
    },
    notice: {
      main: '#ffffff',
    },
    background: {
      default: '#fafafa',
    },
    highlight: {
      blue: '#E4F5FD',
    }
  },
});

export default theme;
