import React, { useState }from 'react';
import { useSelector } from "react-redux";

import { Drawer, List, ListItem, Link, IconButton } from '@mui/material';

import AdminMenu from './AdminMenu';
import CallReviewerMenu from './CallReviewerMenu';
import CompanyAdminMenu from './CompanyAdminMenu';
import GroupAdminMenu from './GroupAdminMenu';
import OfficeMenu from './OfficeMenu';
import CompanySelector from '../Common/CompanySelector';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  appBarLogoLink: {
    padding: '0 1em',

    overflow: 'hidden',
    '& .appBarLogo': {
      height: '3em',
    },
    '& .appBarLogoClosed': {
      height: '1.5em',
      marginTop: '0.8em',
      marginBottom: '0.8em',
    },
    '&:hover':{
      backgroundColor: '#fff',
    }
  },

  sideBar: {
    flexShrink: 0,
    backgroundColor: '#18202c',
    flexBasis: 240,
    height: '100%',
    overflow: 'hidden',
    "@media print": {
      display: 'none',
    },
  },
  sideBarClosed: {
    '& ul': {
      '& li': {
        display: 'none',
      },
      '& a': {
        width: '56px',
        '& .menuIcon': {
          minWidth: 'auto',
        },
        '& .MuiListItemText-root': {
          display: 'none',
        }
      }
    }
  },
  sideBarPaper: {
    position: 'unset',
  },
  dateBox: {
    width: '100%',
    fontSize: '1.2em',
  },
  timeBox: {
    width: '100%',
    fontSize: '1.8em',
  },
  versionBox: {
    marginTop: 'auto',
    opacity: 0.5,
  }
}));

const SideBar = () => {
  const classes = useStyles();

  const mongoUser = useSelector( (state) => state.auth.user );

  const [isOpen, setIsOpen] = useState(true);

  return (
    <Drawer
       className={ (isOpen) ? classes.sideBar : classes.sideBarClosed }
       classes={{
          paper: classes.sideBarPaper,
        }}
       variant="permanent"
       open={false}
    >

        <List>
          <ListItem button key="dashboard-home-logo" component={Link} to="/dashboard" className={classes.appBarLogoLink}>
            { (isOpen === true ) &&
              <img className="appBarLogo" src="/img/ringanalytics-brand.jpeg" alt="Ring Analytics" title="Ring Analytics" />
            }
            { (isOpen === false ) &&
              <img className="appBarLogoClosed" src="/img/r-logo-ringai-64px.png" alt="Ring Analytics" title="Ring Analytics" />
            }
          </ListItem>
        </List>

      { mongoUser.customData.role === 'admin' && <AdminMenu /> }
      { mongoUser.customData.role === 'callreviewer' && <CallReviewerMenu /> }
      { mongoUser.customData.role === 'companyadmin' && <CompanyAdminMenu /> }
      { mongoUser.customData.role === 'groupadmin' && <GroupAdminMenu /> }
      { mongoUser.customData.role === 'officeadmin' && <OfficeMenu /> }

      { (isOpen === true ) &&
        <List className={classes.versionBox}>
          <ListItem key="company-selector">
              { ( !mongoUser.customData.useDemoAI || (mongoUser.customData.useDemoAI && mongoUser.customData.useDemoAI !== true) ) &&
                <CompanySelector />
              }
              <IconButton title="Fold Menu" sx={{margin: '0.2em 0 0.2em 0.4em'}} onClick={()=>{setIsOpen(false)}} className={classes.versionBox}>
                <ArrowBackIosNewIcon />
              </IconButton>
          </ListItem>
        </List>
      }

      { (isOpen === false ) &&
        <IconButton sx={{margin: '0.25em'}} title="Show Full Menu" onClick={()=>{setIsOpen(true)}} className={classes.versionBox}>
          <ArrowForwardIosIcon />
        </IconButton>
      }


    </Drawer>
  );
}

export default SideBar;
