import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import AppRouter from './routers/AppRouter';

import { LicenseInfo } from '@mui/x-license-pro';

import theme from './config/theme';
import muiLicense from './config/mui';
import configureStore from './store';

const store = configureStore();

LicenseInfo.setLicenseKey(muiLicense.key);

render(
  <Provider store={store}>
    <BrowserRouter >
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <AppRouter />
        </ThemeProvider>
      </StyledEngineProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
