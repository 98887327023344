import { combineReducers } from 'redux';

import auth from './auth.reducer';
import db from './db.reducer';
import app from './app.reducer';

const rootReducer = combineReducers({
  auth,
  db,
  app
});

export default rootReducer;
