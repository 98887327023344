import React from 'react';
import { useDispatch } from "react-redux";

import { Container, Box, Typography, Button } from '@mui/material';

import { doLogoutUser } from "../utils";

export const AccessDeniedPage = () => {
  const dispatch = useDispatch();
  
  const handleLogout = () => {
    dispatch( doLogoutUser() );
  };
  return (
    <Container maxWidth="xs">
      <Box display="flex" height={1} >
        <Box width={1} m="auto" textAlign="center">
          <Typography align="center" color="error" variant="h4" component="h4">ACCESS DENIED</Typography>
          <br/>
          <br/>
          <Button variant="contained" onClick={handleLogout} >Exit App</Button>
        </Box>
      </Box>
    </Container>
  );
}
