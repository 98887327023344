import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import Moment from 'react-moment';
import moment from 'moment-timezone';

export const UserDateTime = ({ dateObj, formatStr }) => {
  const mongoUser = useSelector((state) => state.auth.user);
  const [selectedTimeZone, setSelectedTimeZone] = useState(null);
  const [selectedFormat, setSelectedFormat] = useState('ddd, MMM D, h:mm A');

  useEffect(() => {
    if (!!mongoUser.customData.timeZone && mongoUser.customData.timeZone !== '') {
      setSelectedTimeZone(mongoUser.customData.timeZone);
    } else {
      setSelectedTimeZone(moment.tz.guess());
    }
  }, [mongoUser]);

  useEffect(() => {
    setSelectedFormat(formatStr);
  }, [formatStr]);

  return (
    <Moment format={selectedFormat} tz={selectedTimeZone}>{dateObj}</Moment>
  );
};

export default UserDateTime;
