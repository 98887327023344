import * as Realm from "realm-web";

import mongoConfig from "../mongo";

import {
  dialogInProgress,
  dialogSuccess,
  dialogError,
  authInProgress,
  authRefresh,
  authSuccess,
  authFail,
  authMessage,
  appSomethingIsLoading,
  appAllLoaded
} from '../actions';


export const doVerifyAuth = (user) => dispatch => {
  const currentApp = Realm.App.getApp(mongoConfig.realmAppId);

  if ( !user || !user.isLoggedIn ) {
    if ( !!currentApp.currentUser && currentApp.currentUser !== null) {
      dispatch(authSuccess(currentApp.currentUser));
    } else {
      dispatch(authFail());
    }
  }
};

export const doSetNewPassword = (newPassword, token, tokenId) => (dispatch) => {
  dispatch(authInProgress());

  const currentApp = Realm.App.getApp(mongoConfig.realmAppId);

  currentApp.emailPasswordAuth.resetPassword(token, tokenId, newPassword).then(() => {
    dispatch(authMessage('Updated. You can login now with your new password.'));
  }, reason => {
    dispatch(authFail({message: 'Setting new password failed: '+reason.error}));
  });
};

export const doIssuePasswordReset = (email) => (dispatch) => {
  dispatch(appSomethingIsLoading());

  const currentApp = Realm.App.getApp(mongoConfig.realmAppId);
  currentApp.emailPasswordAuth.sendResetPasswordEmail(email).then(
    () => {
      console.log('emailed');
      dispatch(appAllLoaded());
    }, (error) => {
      console.log('error');
      console.log(error);
    }
  );
};

export const doResetPasswordEmail = (email) => (dispatch) => {
  dispatch(authInProgress());

  const currentApp = Realm.App.getApp(mongoConfig.realmAppId);

  currentApp.emailPasswordAuth.sendResetPasswordEmail(email).then(() => {
    dispatch(authMessage('Password reset instructions was emailed to you.'));
  });
};


export const doAzureLogin = () => {
  // let navigate = useNavigate();
  //
  // const azureLoginURL = 'https://login.microsoftonline.com/80d2f5dc-ea74-4efe-a1ab-e300f61957e9/oauth2/v2.0/authorize?client_id=534cd2ca-53ca-4724-92bb-4cf9022c39aa&response_type=token&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fazureauth&response_mode=fragment&scope=openid%20profile';
  // navigate(azureLoginURL);
  //
  // return false;
  // dispatch(authInProgress());
  //
  // const currentApp = Realm.App.getApp(mongoConfig.realmAppId);
  // console.log(currentApp);
  //
  // // const credentials = Realm.Credentials.jwt('raphael_eyecarepro.net#EXT#@raphaeleyecarepro.onmicrosoft.com');
  // const credentials = Realm.Credentials.jwt('eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c');
  // console.log(credentials);
  //
  // currentApp.logIn(credentials).then(user => {
  //   console.log(user);
  // }, reason => {
  //   console.log(reason);
  //   dispatch(authFail({message: reason.error}));
  // });
}

export const doAzureHandleToken = (token) => (dispatch) => {
  dispatch(authInProgress());

  const currentApp = Realm.App.getApp(mongoConfig.realmAppId);
  const credentials = Realm.Credentials.jwt(token);

  currentApp.logIn(credentials).then(user => {
    console.log(user);
    console.log(currentApp.currentUser);
    if ( user.id === currentApp.currentUser.id ) {
      if ( !user.customData.role ) {
        // user logs in first time with a google
        // no record is created in database for him

        if ( user.profile && user.profile.name ) {
          console.log('update profile');
          // update userID in usersdata collection
          user
            .mongoClient(mongoConfig.realmServiceName)
            .db(mongoConfig.databaseName)
            .collection('usersdata')
            .updateOne(
              { username: user.profile.name },
              { $set: {'userID': user.id} }
            ).then(() => {

              currentApp.currentUser.refreshCustomData().then( () => {
                if ( !currentApp.currentUser.customData.role ) {
                  currentApp.currentUser.logOut().then(() => {
                    dispatch(authFail({message: 'No user associated with your Azure account.'}));
                  });
                } else {
                  dispatch(authSuccess(currentApp.currentUser));
                }
              });

            }).catch((err) => {
              console.log('UserID update error: ' + err);
              currentApp.currentUser.logOut().then(() => {
                dispatch(authFail({message: 'UserID update error.'}));
              });
            });

        } else {
          console.log('before logout');
          currentApp.currentUser.logOut().then(() => {
            dispatch(authFail({message: 'Azure did not grant access to email address.'}));
          });
        }

      } else {
        // normal user
        console.log('normal login');
        dispatch(authSuccess(user));
      }
    }
    else dispatch(authFail({message: 'User ID does not match Application User ID.'}));
  }, reason => {
    console.log(reason);
    dispatch(authFail({message: 'User with such email and/or password - not found.'}));
  });


//   let splitHash = hash.split('&');
//
//   if ( splitHash[0] && splitHash[0].indexOf('#access_token=') === 0 ) {
//     const token = splitHash[0].replace('#access_token=', '');
//
// console.log(token);
//     // const currentApp = Realm.App.getApp(mongoConfig.realmAppId);
//     // const credentials = Realm.Credentials.jwt(token);
//     //
//     // currentApp.logIn(credentials).then(user => {
//     //   console.log(user);
//     // }, reason => {
//     //   console.log(reason);
//     // });
//   }
  // console.log('handle redirect');
  // Realm.handleAuthRedirect();
}

export const doGoogleHandleRedirect = () => {
  console.log('handle redirect');
  Realm.handleAuthRedirect();
}

export const doGoogleLogin = () => (dispatch) => {
  dispatch(authInProgress());

  const currentApp = Realm.App.getApp(mongoConfig.realmAppId);
  // const credentials = Realm.Credentials.google('http://localhost:3000/googleauth');
  const credentials = Realm.Credentials.google('https://www.ringanalytics.ai/googleauth');

  currentApp.logIn(credentials).then(user => {
    if ( user.id === currentApp.currentUser.id ) {

      if ( !user.customData.role ) {
        // user logs in first time with a google

        if ( user.profile && user.profile.email ) {
          // console.log(user.profile.email);
          // update userID in usersdata collection
          user
            .mongoClient(mongoConfig.realmServiceName)
            .db(mongoConfig.databaseName)
            .collection('usersdata')
            .updateOne(
              { username: user.profile.email },
              { $set: {'userID': user.id} }
            ).then(() => {

              currentApp.currentUser.refreshCustomData().then( () => {
                if ( !currentApp.currentUser.customData.role ) {
                  currentApp.currentUser.logOut().then(() => {
                    dispatch(authFail({message: 'No user associated with your Google account.'}));
                  });
                } else {
                  dispatch(authSuccess(currentApp.currentUser));
                }
              });

            }).catch((err) => {
              console.log('UserID update error: ' + err);
              currentApp.currentUser.logOut().then(() => {
                dispatch(authFail({message: 'UserID update error.'}));
              });
            });

        } else {
          currentApp.currentUser.logOut().then(() => {
            dispatch(authFail({message: 'Google did not grant access to email address.'}));
          });
        }

      } else {
        // normal user
        console.log('normal login');
        dispatch(authSuccess(user));
      }
    }
    else dispatch(authFail({message: 'User ID does not match Application User ID.'}));
  }, reason => {
    console.log(reason);
    dispatch(authFail({message: 'User with such email and/or password - not found.'}));
  });

};

export const doLoginUser = (email, password) => (dispatch) => {
  dispatch(authInProgress());

  const currentApp = Realm.App.getApp(mongoConfig.realmAppId);
  const credentials = Realm.Credentials.emailPassword(email, password);

  currentApp.logIn(credentials).then(user => {
    if ( user.id === currentApp.currentUser.id ) {
      // console.log(user.customData);
      // console.log(user.customData.role);

      if ( !user.customData.role ) {
        // first time login user
        console.log('first time login');
        dispatch(doRefreshCustomData());
      } else {
        // normal user
        console.log('normal login');
        dispatch(authSuccess(user));
      }
    }
    else dispatch(authFail({message: 'User ID does not match Application User ID.'}));
  }, reason => {
    console.log(reason);
    dispatch(authFail({message: 'User with such email and/or password - not found.'}));
  });
};

export const doLogoutUser = () => () => {

  const currentApp = Realm.App.getApp(mongoConfig.realmAppId);

  currentApp.currentUser.logOut().then(() => {
    window.location.reload();
  });
};

export const doRefreshCustomData = () => (dispatch) => {
  dispatch(authRefresh());

  const currentApp = Realm.App.getApp(mongoConfig.realmAppId);

  currentApp.currentUser.refreshCustomData().then( () => {
    dispatch(authSuccess(currentApp.currentUser));
  });
};

export const doRemoveUser = (userToBeDeleted) => () => {
  console.log('do remove');
  const currentApp = Realm.App.getApp(mongoConfig.realmAppId);

  console.log(currentApp.currentUser);
  console.log(userToBeDeleted);

  currentApp.removeUser(userToBeDeleted).then( () => {
    // dispatch(dialogSuccess());
    console.log('after delete');
  }, reason => {
    // dispatch(dialogError(reason.error));
    console.log(reason);
  });

};

export const doCreateUser = (userDetails) => (dispatch) => {
  dispatch(dialogInProgress());
  const currentApp = Realm.App.getApp(mongoConfig.realmAppId);

  currentApp.emailPasswordAuth.registerUser(userDetails).then( () => {
    dispatch(dialogSuccess());
  }, reason => {
    dispatch(dialogError(reason.error));
  });

};

export const doEmailUserInvitation = (mongoUser, userDetails) => (dispatch) => {
  dispatch(dialogInProgress());

  mongoUser.callFunction("SendMailUserInvitation", userDetails)
    .then( (inviteSentResults) => {
      console.log(inviteSentResults);
      dispatch(dialogSuccess());
    }).catch((err) => {
      console.log('invitation email error: ' + err);
      dispatch(dialogError(err));
    });
};


export const doResendConfirmUserEmail = (email) => (dispatch) => {
  dispatch(appSomethingIsLoading());

  const currentApp = Realm.App.getApp(mongoConfig.realmAppId);

  currentApp.emailPasswordAuth.resendConfirmationEmail({email: email}).then(
    () => {
      console.log('emailed');
      dispatch(appAllLoaded());
    }, reason => {
      console.log(reason);
      dispatch(appAllLoaded());
    }
  );
}

export const doConfirmUserEmail = (token, tokenId) => {

  // console.log('confirm');
  // console.log(token);
  // console.log(tokenId);

  const currentApp = Realm.App.getApp(mongoConfig.realmAppId);

  if ( currentApp.currentUser ) {
    return currentApp.currentUser.logOut().then(() => {
      return currentApp.emailPasswordAuth.confirmUser({token: token, tokenId: tokenId}).then( () => {
        return true;
      }, reason => {
        console.log(reason);
        return false;
      });
    });
  } else {
    return currentApp.emailPasswordAuth.confirmUser({token: token, tokenId: tokenId}).then( () => {
      return true;
    }, reason => {
      console.log(reason);
      return false;
    });
  }

};
