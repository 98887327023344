import React , { Suspense } from 'react';
import { Box } from '@mui/material';

// import CallsPageTemplate from '../../components/Calls/CallsPageTemplate';
const CallsPageTemplate = React.lazy(() => import('../../components/Calls/CallsPageTemplate'));
import PageTitle from '../../components/Common/PageTitle';

export const IsSpamCallsPage = (props) => {

  return (
    <Box>
      <PageTitle thepage="/calls/isspam" />
      <Suspense fallback={<span>...</span>}>
        <CallsPageTemplate
          props={props}
          callsSection='isspam'
          lineChartsHeading='Spam Calls'
          heatmapColors='oranges'
        />
      </Suspense>
    </Box>
  );
};
