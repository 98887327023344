import React from 'react';

import { Container, Box, Typography } from '@mui/material';

export const NotFoundPage = () => (
  <Container maxWidth="xs">
    <Box display="flex" height={1} >
      <Box width={1} m="auto">
        <Typography align="center" color="error" variant="h4" component="h4">404 - Page not found</Typography>
      </Box>
    </Box>
  </Container>
);
