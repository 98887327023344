import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";

import makeStyles from '@mui/styles/makeStyles';
import { Container, Box, Grid } from '@mui/material';
import { Alert, AlertTitle } from '@mui/material';

import { LoginForm } from "../components/Auth/LoginForm";
import { ResetPasswordForm } from "../components/Auth/ResetPasswordForm";
import { SetNewPasswordForm } from "../components/Auth/SetNewPasswordForm";

import { doAzureHandleToken, doLoginUser, doResetPasswordEmail, doSetNewPassword, doGoogleLogin } from "../utils";

const useStyles = makeStyles(() => ({
  logo: {
    maxWidth: '100%',
    display: 'flex',
    margin: '2em auto'
  }
}));

export const LoginPage = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const query = new URLSearchParams(useLocation().search);

  const [token] = useState(query.get("token") || null);
  const [tokenId] = useState(query.get("tokenId") || null);


  const authMessage = useSelector( (state) => state.auth.message );
  const authError = useSelector( (state) => state.auth.authError );

  const [authStage, setAuthStage] = useState( (token && tokenId) ? 'setnew' : 'login');

  const [searchParams] = useSearchParams();
  const [azureAccessTokenValue, setAzureAccessTokenValue] = useState(null);

  useEffect(() => {
    let accessToken = searchParams.get("azure_access_token");
    if ( accessToken && accessToken !== '' ) {
      setAzureAccessTokenValue(accessToken);
    }
  }, [searchParams]);

  useEffect(() => {
    if ( azureAccessTokenValue !== null ) {
      dispatch(doAzureHandleToken(azureAccessTokenValue));
      navigate('/login');
    }
  }, [azureAccessTokenValue]);

  const handleLoginSubmit = (email, password) => {
    dispatch( doLoginUser(email, password) );
  }

  const handleGoogleLogin = () => {
    dispatch( doGoogleLogin() );
  }

  const handleResetSubmit = (email) => {
    dispatch( doResetPasswordEmail(email) );
  }

  const handleSetNewSubmit = (newPassword) => {
    dispatch( doSetNewPassword(newPassword, token, tokenId) );
    // query.delete()
    // setAuthStage('login');
  }

  const handleStateChange = (newStage) => {
    setAuthStage(newStage);
  }

  const classes = useStyles();

  return (
    <Container maxWidth="xs">
      <Box my={4}>
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <img className={classes.logo} src="/img/ringanalytics-brand.png" alt="Ring Analytics" title="Ring Analytics"/>
            </Grid>
          </Grid>
          { authMessage && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Alert severity="info">
                  <AlertTitle>Important</AlertTitle>
                  {authMessage}
                </Alert>
              </Grid>
            </Grid>
          )}
          { authStage === 'login' && <LoginForm onLoginSubmit={handleLoginSubmit} onStageChange={handleStateChange} onGoogleLogin={handleGoogleLogin}  /> }
          { authStage === 'reset' && <ResetPasswordForm onResetSubmit={handleResetSubmit} onStageChange={handleStateChange}  /> }
          { authStage === 'setnew' && <SetNewPasswordForm onSetNewPasswordSubmit={handleSetNewSubmit} onStageChange={handleStateChange}  /> }
          <br />
          { authError && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Alert severity="error">
                  <AlertTitle>Error</AlertTitle>
                  {authError.message}
                </Alert>
              </Grid>
            </Grid>
          )}

        </Box>
      </Box>
    </Container>
  );
};
