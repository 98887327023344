import React, { Suspense } from 'react';
import { Box } from '@mui/material';

const CallsPageTemplate = React.lazy(() => import('../../components/Calls/CallsPageTemplate'));
import PageTitle from '../../components/Common/PageTitle';

export const AllCallsPage = (props) => {

  return (
    <Box>
      <PageTitle thepage="/calls/all" />
      <Suspense fallback={<span>...</span>}>
        <CallsPageTemplate
          props={props}
          callsSection='all'
          lineChartsHeading='All Calls'
          heatmapColors='yellow_green'
          showMultiChart={false}
        />
      </Suspense>
    </Box>
  );
};
