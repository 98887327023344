import { authConstants as ac } from '../constants';

// this is auth state
const initialState = {
  inProgress: false,
  inRefresh: false,
  isAuthenticated: false,
  authError: null,
  message: null,
  user: null,
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case ac.AUTH_INPROGRESS:
      return {
        ...state,
        inProgress: true,
        message: null,
      };
    case ac.AUTH_REFRESH:
      return {
        ...state,
        inRefresh: true,
      };
    case ac.AUTH_SUCCESS:
      return {
        ...state,
        inProgress: false,
        inRefresh: false,
        isAuthenticated: true,
        authError: null,
        message: null,
        user: action.user,
      };
    case ac.AUTH_FAIL:
      return {
        ...state,
        inProgress: false,
        inRefresh: false,
        isAuthenticated: false,
        authError: action.error,
        message: null,
        user: null,
      };
    case ac.AUTH_MESSAGE:
      return {
        ...state,
        inProgress: false,
        inRefresh: false,
        isAuthenticated: false,
        authError: null,
        message: action.message,
        user: null,
      };
    default:
      return state;
  }
};

export default auth;
