import React from 'react';

import { NavLink } from 'react-router-dom';

const StyledNavLink = React.forwardRef((props, ref) => (
  <NavLink
    ref={ref}
    to={props.to}
    className={props.className}
  >
    {props.children}
  </NavLink>
));

StyledNavLink.displayName = "StyledNavLink";

export default StyledNavLink;
