import React from 'react';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Box, List, ListItem, ListSubheader, ListItemIcon, ListItemText, Divider, Collapse} from '@mui/material';

import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PhoneMissedIcon from '@mui/icons-material/PhoneMissed';
import HomeIcon from '@mui/icons-material/Home';
import GroupIcon from '@mui/icons-material/Group';
import ApartmentIcon from '@mui/icons-material/Apartment';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import DownloadIcon from '@mui/icons-material/Download';
import BugReportIcon from '@mui/icons-material/BugReport';
import SettingsPhoneIcon from '@mui/icons-material/SettingsPhone';

import StyledNavLink from '../Common/StyledNavLink';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  menuContainer: {
    '& .nestedList': {
      '& .MuiListItemIcon-root': {
        marginLeft: '0.5em',
        marginRight: '-0.5em',
        opacity: '0.5',
        minWidth: '3em',
      }
    },
    '& .menuIcon': {
      minWidth: '3em',
    },
  },
  menuIcon: {
    minWidth: '3em',
  },
  menuLink: {
    color: theme.palette.sidebar.link,
  }
}));

const GroupAdminMenu = () => {
  const classes = useStyles();

  let location = useLocation();

  const [isAnsweredOpen, setIsAnsweredOpen] = useState(false);
  const [isMissedCallsOpen, setIsMissedCallsOpen] = useState(false);

  useEffect(() => {

    if ( location.pathname.startsWith('/calls/answered')
      || location.pathname.startsWith('/calls/booked')
      || location.pathname.startsWith('/calls/notbooked')
      || location.pathname.startsWith('/calls/other') ) {
      setIsAnsweredOpen(true);
    } else {
      setIsAnsweredOpen(false);
    }

    if ( location.pathname.startsWith('/calls/busy')
        || location.pathname.startsWith('/calls/missed')
        || location.pathname.startsWith('/calls/noanswer')
        || location.pathname.startsWith('/calls/hangup')
        || location.pathname.startsWith('/calls/voicemail') ) {
      setIsMissedCallsOpen(true);
    } else {
      setIsMissedCallsOpen(false);
    }

  }, [location]);

  return (
    <Box className={classes.menuContainer}>
        <List>
          <ListItem button key="dashboard-home" component={StyledNavLink} selected={location.pathname.startsWith("/dashboard")} to="/dashboard" className={classes.menuLink}>
            <ListItemIcon className={classes.menuIcon}><HomeIcon /></ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>

          <ListItem button component={StyledNavLink} selected={location.pathname.startsWith("/calls/all")} to="/calls/all">
            <ListItemIcon className="menuIcon"><PhoneInTalkIcon /></ListItemIcon>
            <ListItemText primary="All Calls" />
          </ListItem>

          <ListItem button component={StyledNavLink} selected={location.pathname.startsWith("/calls/answered")} to="/calls/answered">
            <ListItemIcon className="menuIcon"><AttachMoneyIcon /></ListItemIcon>
            <ListItemText primary="Appointment Calls" />
          </ListItem>
          <Collapse in={isAnsweredOpen}>
            <List disablePadding dense className="nestedList">
              <ListItem button component={StyledNavLink} selected={location.pathname.startsWith("/calls/booked")} to="/calls/booked">
                <ListItemIcon><SubdirectoryArrowRightIcon /></ListItemIcon>
                <ListItemText primary="$ Booked" />
              </ListItem>
              <ListItem button component={StyledNavLink} selected={location.pathname.startsWith("/calls/notbooked")} to="/calls/notbooked">
                <ListItemIcon><SubdirectoryArrowRightIcon /></ListItemIcon>
                <ListItemText primary="$ Not Booked" />
              </ListItem>
            </List>
          </Collapse>

          <ListItem button component={StyledNavLink} selected={location.pathname.startsWith("/calls/other")} to="/calls/other">
            <ListItemIcon className="menuIcon"><SettingsPhoneIcon /></ListItemIcon>
            <ListItemText primary="Service Calls" />
          </ListItem>

          <ListItem button component={StyledNavLink} selected={location.pathname.startsWith("/calls/missed")} to="/calls/missed">
            <ListItemIcon className="menuIcon"><PhoneMissedIcon /></ListItemIcon>
            <ListItemText primary="Missed Calls" />
          </ListItem>
          <Collapse in={isMissedCallsOpen}>
            <List disablePadding dense className="nestedList">
              <ListItem button component={StyledNavLink} selected={location.pathname.startsWith("/calls/busy")} to="/calls/busy">
                <ListItemIcon><SubdirectoryArrowRightIcon /></ListItemIcon>
                <ListItemText primary="Busy Signal" />
              </ListItem>
              <ListItem button component={StyledNavLink} selected={location.pathname.startsWith("/calls/noanswer")} to="/calls/noanswer">
                <ListItemIcon><SubdirectoryArrowRightIcon /></ListItemIcon>
                <ListItemText primary="No Answer" />
              </ListItem>
              <ListItem button component={StyledNavLink} selected={location.pathname.startsWith("/calls/hangup")} to="/calls/hangup">
                <ListItemIcon><SubdirectoryArrowRightIcon /></ListItemIcon>
                <ListItemText primary="Hang Up" />
              </ListItem>
              <ListItem button component={StyledNavLink} selected={location.pathname.startsWith("/calls/voicemail")} to="/calls/voicemail">
                <ListItemIcon><SubdirectoryArrowRightIcon /></ListItemIcon>
                <ListItemText primary="Voicemail" />
              </ListItem>
            </List>
          </Collapse>

          <ListItem button component={StyledNavLink} selected={location.pathname.startsWith("/calls/isspam")} to="/calls/isspam">
            <ListItemIcon className="menuIcon"><BugReportIcon /></ListItemIcon>
            <ListItemText primary="Spam Calls" />
          </ListItem>

          <ListItem button component={StyledNavLink} selected={location.pathname.startsWith("/data")} to="/data">
            <ListItemIcon className="menuIcon"><DownloadIcon /></ListItemIcon>
            <ListItemText primary="Data Download" />
          </ListItem>

        </List>
        <Divider />
        <List>

          <ListItem button component={StyledNavLink}  selected={location.pathname.startsWith("/calls/tagged")} to="/calls/tagged">
            <ListItemIcon className="menuIcon"><LocalOfferIcon /></ListItemIcon>
            <ListItemText primary="Tagged Calls" />
          </ListItem>

        </List>
        <Divider />
        <List>
          <ListSubheader disableSticky>Manage</ListSubheader>

          <ListItem button component={StyledNavLink} selected={location.pathname.startsWith("/manage/groups")} to="/manage/groups" >
            <ListItemIcon className={classes.menuIcon}><GroupIcon /></ListItemIcon>
            <ListItemText primary="Groups" />
          </ListItem>
          <ListItem button component={StyledNavLink} selected={location.pathname.startsWith("/manage/offices")} to="/manage/offices" >
            <ListItemIcon className={classes.menuIcon}><ApartmentIcon /></ListItemIcon>
            <ListItemText primary="Offices" />
          </ListItem>

        </List>
    </Box>
  );
}

export default GroupAdminMenu;
