import React, { useState, useEffect } from 'react';

import { Grid, TextField, Typography, Button, Link, Alert } from '@mui/material';

import GoogleIcon from '@mui/icons-material/Google';

export const LoginForm = (props) => {

  const { onLoginSubmit, onStageChange, onGoogleLogin } = props;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showUppercaseNotice, setUppercaseNotice] = useState(false);

  const handleEmailChange = ({target}) => {
    setEmail(target.value);
  }

  const handlePasswordChange = ({target}) => {
    setPassword(target.value);
  }

  const handleLoginSubmit = (target) => {
    target.preventDefault();
    onLoginSubmit(email, password);
  }

  const handleResetClick = (target) => {
    target.preventDefault();
    onStageChange('reset');
  }

  useEffect(() => {
    const lowerEmail = email.toLowerCase();
    if ( lowerEmail === email ) {
      setUppercaseNotice(false);
    } else {
      setUppercaseNotice(true);
    }
  }, [email]);

  return (
    <form method="POST" onSubmit={handleLoginSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField onChange={handleEmailChange} required fullWidth label="Username Email" name="email" size="small" variant="outlined" />

              { (showUppercaseNotice) &&
                  <Alert severity="warning">Use lower case for username emails.</Alert>
              }
            </Grid>
            <Grid item xs={12}>
              <TextField onChange={handlePasswordChange} required fullWidth label="Password" name="password" size="small" type="password" variant="outlined"/>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2" sx={{color: '#676767'}}>* - required, case sensitive fields</Typography>
        </Grid>
        <Grid item xs={12}>
          <Button color="secondary" fullWidth type="submit" variant="contained">Login</Button>
        </Grid>
        <Grid item justifyContent="center" alignContent="center" alignItems="center" container xs={12}>
          <Link href="#" onClick={handleResetClick}>Reset my Password</Link>
        </Grid>
        <Grid item justifyContent="center" alignContent="center" alignItems="center" container xs={12}>
          <Button startIcon={<GoogleIcon />} color="secondary" fullWidth variant="outlined" onClick={onGoogleLogin}>Login with Google</Button>
        </Grid>
        <Grid item justifyContent="center" alignContent="center" alignItems="center" container xs={12} sx={{fontSize: 'smaller'}}>
          &copy; 2023&nbsp;|&nbsp;<Link href="https://www.eyecarepro.com/terms-of-service" rel="noreferrer" target="_blank">Terms of Service</Link>&nbsp;|&nbsp;<Link href="https://www.eyecarepro.com/privacy-policy" rel="noreferrer" target="_blank">Privacy</Link>
        </Grid>
      </Grid>
    </form>
  );
};
