import React, {useState, useEffect, Fragment} from 'react';
import { useSelector } from "react-redux";
import { Fab, Badge, Card, CardHeader, CardContent, IconButton, List, ListItem, ListItemText, Typography } from '@mui/material';

import NotificationsIcon from '@mui/icons-material/Notifications';

import CloseIcon from '@mui/icons-material/Close';
import DoneAllIcon from '@mui/icons-material/DoneAll';

import makeStyles from '@mui/styles/makeStyles';

import mongoConfig from "../../mongo";
import moment from 'moment-timezone';

const useStyles = makeStyles((theme) => ({
  notesBubbleButton: {
    position: 'fixed',
    right: '1em',
    bottom: '1em',
    zIndex: 9999,
    '& .MuiBadge-badge': {
      marginTop: '-0.5em',
      marginRight: '-0.5em',
    }
  },
  notesCard: {
    boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
    backgroundColor: theme.palette.chat.background,
    position: 'fixed',
    right: '1em',
    bottom: '1em',
    maxWidth: '25em',
    width: '100%',
    zIndex: 9999,
    '& .MuiCardHeader-root': {
      backgroundColor: theme.palette.chat.heading,
      color: theme.palette.chat.headingText,
      '& .MuiTypography-root': {
        fontWeight: 500,
        fontSize: '0.875rem',
        lineHeight: '1.75',
        letterSpacing: '0.02857em',
        textTransform: 'uppercase',
      },
      '& .MuiIconButton-root': {
        color: theme.palette.chat.headingText,
      },
      '& .MuiCardHeader-avatar button': {
        marginTop: '-4px',
        marginBottom: '-4px',
        marginLeft: '-8px',
      }
    },
  },
  notesList: {
    maxHeight: '28em',
    overflow: 'auto',
    fontSize: '0.8rem',
    '& .MuiListItem-root': {
      '& button': {
        marginLeft: '90%'
      },
      '& .MuiListItemText-primary': {
        backgroundColor: theme.palette.chat.noteBubbleBg,
        borderRadius: '0.5em',
        padding: '0.25em 0.5em',
      },
      '& .newNote .MuiListItemText-primary': {
        borderLeft: '2px solid '+theme.palette.chat.heading,
      },
      '& .oldNote .MuiListItemText-primary': {
        borderLeft: '2px solid '+theme.palette.chat.noteBubbleBg,
      },
      '& .MuiListItemText-secondary': {
        textAlign: 'right',
        padding: '0 0.5em',
        fontSize: 'smaller',
      },
      marginBottom: '0.5em'
    }
  },
  notesActions: {
    '& textarea': {
      padding: '8.5px 14px',
      width: '100%',
      fontWeight: 400,
      fontSize: '0.8rem',
      lineHeight: '1.4375em',
      letterSpacing: '0.00938em',
      borderRadius: '4px',
    }
  }
}));

export const OfficeAdminNotesCard = ( ) => {
  const mongoUser = useSelector( (state) => state.auth.user );
  const userTimeZone = ( !!mongoUser.customData.timeZone && mongoUser.customData.timeZone !== '' ) ? mongoUser.customData.timeZone : moment.tz.guess();

  const classes = useStyles();

  const [isChatOpen, setIsChatOpen] = useState(false);

  const [reloadNotes, setReloadNotes] = useState(false);
  const [officeNotes, setOfficeNotes] = useState(undefined);
  const [newNotesCount, setNewNotesCount] = useState(0);


  // const [saveInProgress, setSaveInProgress] = useState(false);

  // const [officeAdminsForDropdown, setOfficeAdminsForDropdown] = useState([]);

  const handleCloseChat = () => {
    setIsChatOpen(false);
  }

  const handleReadIt = (noteID) => {
    mongoUser
      .mongoClient(mongoConfig.realmServiceName)
      .db(mongoConfig.databaseName)
      .collection('notes')
      .updateOne(
        { "_id": noteID },
        {
            $push: {
                readByUsers: mongoUser.id
            }
        }
      )
      .then(() => {

        // reload notes from DB
        setReloadNotes(Date.now());

//         const updatedOfficeNotes = officeNotes.map(oneNote => {
//           let newReadByUsers = oneNote.readByUsers;
//           newReadByUsers.push(mongoUser.id);
// console.log(oneNote);
//
// console.log(oneNote._id.toString(), noteID.toString());
// return oneNote;
//           // return ( oneNote._id.toString() === noteID.toString() )
//           //   ? { ...oneNote, readByUsers: newReadByUsers }
//           //   : oneNote
//         });
//
//         setOfficeNotes(updatedOfficeNotes);

        // console.log('updated');
      }).catch((err) => {
        console.log('Note update error: ' + err);
      });
  }

  useEffect(() => {
    if ( !mongoUser.customData || !mongoUser.customData.officesAccess || mongoUser.customData.officesAccess.length === 0 ) return;

    let officesAccess = mongoUser.customData.officesAccess;

    // load notes from all offices
    mongoUser
      .mongoClient(mongoConfig.realmServiceName)
      .db(mongoConfig.databaseName)
      .collection('notes')
      .find(
        { officeID: { $in: officesAccess} },
        {
          sort: { utcDateTime: -1 },
        }
      )
      .then((foundNotes) => {
        setOfficeNotes(foundNotes);
      }).catch((err) => {
        setOfficeNotes(undefined);
        console.log('Notes Loading error: ' + err);
      });

  }, [mongoUser, reloadNotes]);

  useEffect(() => {
    if ( !officeNotes || officeNotes.length === 0 ) {
      setNewNotesCount(0);
    } else {
      let count = 0;

      officeNotes.forEach((oneNote) => {
        if ( !oneNote.readByUsers || oneNote.readByUsers.length === 0 || !oneNote.readByUsers.includes(mongoUser.id) ) {
          count++;
        }
      });

      setNewNotesCount(count);
    }
  }, [officeNotes, mongoUser]);


  return (
    <Fragment>
      { (!isChatOpen) &&
        <Fab
          color="success"
          variant="round"

          className={classes.notesBubbleButton}
          onClick={() => setIsChatOpen(true)}
          >
          <Badge badgeContent={newNotesCount} color="error">
            <NotificationsIcon />
          </Badge>
        </Fab>
      }

      { ( isChatOpen ) &&
        <Card className={classes.notesCard}>

          <CardHeader
            action={
              <IconButton onClick={() => {handleCloseChat()}} aria-label="close">
                <CloseIcon />
              </IconButton>
            }
            title="Efficiency Notes by Ring Analytics"
          />

          { ( officeNotes && officeNotes.length > 0 ) &&
            <CardContent>
              <List className={classes.notesList}>
                { officeNotes.map((oneNote) => (
                    <ListItem key={oneNote._id} disablePadding>
                      <ListItemText
                        className={ (!oneNote.readByUsers.includes(mongoUser.id)) ? 'newNote' : 'oldNote'}
                        primary={
                          <Fragment>
                            <Typography variant="subtitle2">{oneNote.officeName}</Typography>
                            <Typography variant="body2">{oneNote.note}</Typography>

                            { (!oneNote.readByUsers.includes(mongoUser.id)) &&
                              <IconButton title="Ok, mark read" size="small" color="info" variant="contained" onClick={() => {handleReadIt(oneNote._id)}} aria-label="close">
                                <DoneAllIcon />
                              </IconButton>
                            }
                          </Fragment>
                        }
                        secondary={moment(oneNote.utcDateTime).tz(userTimeZone).format('MMM D Y, h:mm A')+' by '+oneNote.byUserName} />
                    </ListItem>
                  ))
                }
              </List>
            </CardContent>
          }

          { ( officeNotes.length === 0) &&
            <CardContent>
              <Typography variant="caption">No notes for you ...</Typography>
            </CardContent>
          }


        </Card>
      }

    </Fragment>

  );
}

export default OfficeAdminNotesCard;
