import React, { useState } from 'react';

import { Grid, TextField, Button, Link } from '@mui/material';

export const SetNewPasswordForm = (props) => {

  const { onSetNewPasswordSubmit, onStageChange } = props;

  const [newPassword, setNewPassword] = useState('');

  const handlePasswordChange = ({target}) => {
    setNewPassword(target.value);
  }

  const handleNewPasswordSubmit = (target) => {
    target.preventDefault();
    onSetNewPasswordSubmit(newPassword);
  }

  const handleBackLoginClick = (target) => {
    target.preventDefault();
    onStageChange('login');
  }

  return (
    <form method="POST" onSubmit={handleNewPasswordSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField onChange={handlePasswordChange} required fullWidth label="New Password" name="password" size="small" type="password" variant="outlined"/>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Button color="secondary" fullWidth type="submit" variant="contained">Set New Password</Button>
        </Grid>
        <Grid justifyContent="center" alignContent="center" alignItems="center" container xs={12}>
          <Link href="#" onClick={handleBackLoginClick}>Back to Login Screen</Link>
        </Grid>
      </Grid>
    </form>
  );
};
