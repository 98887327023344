import React from 'react';
import { useSelector } from "react-redux";
import { Navigate, useLocation } from 'react-router-dom';

import PageRoot from '../pages/PageRoot';
import { pagesConstants as pc } from '../constants';

const RoleRouter = ({children, roles}) => {
  const mongoUser = useSelector( (state) => state.auth.user );
  const authProvider = useSelector( (state) => (state.auth && state.auth.user && state.auth.user.providerType) ? state.auth.user.providerType : null );

  const isAuthenticated = useSelector( (state) => state.auth.isAuthenticated );
  const authInProgress = useSelector( (state) => state.auth.inProgress );
  const authInRefresh = useSelector( (state) => state.auth.inRefresh );

  const userHasRequiredRole = (!!mongoUser && !!mongoUser.customData && roles !== null && roles.includes(mongoUser.customData.role)) ? true : false;

  let location = useLocation();

  if ( !isAuthenticated ) {
    if ( authInRefresh || authInProgress ) {
      return <PageRoot page={pc.PAGE_INREFRESH} />
    }
    return <PageRoot page={pc.PAGE_LOGIN} />
  } else if ( isAuthenticated ) {

    if ( authInRefresh || authInProgress ) {
      return <PageRoot page={pc.PAGE_INREFRESH} />
    }

    // authenticated trying to access anonymous pages, like "/login"
    if ( !!mongoUser && !!mongoUser.customData && !!mongoUser.customData.role && mongoUser.customData.role === 'callreviewer' && location.pathname !== '/calls/review') {
      return <Navigate to="/calls/review" state={{ from: location }} />;
    }

    if ( roles === null && authProvider !== 'anon-user' ) {
      return <Navigate to="/dashboard" state={{ from: location }} />;
    } else if ( roles !== null && !userHasRequiredRole ) {
      // has no access to this page by his role
      return <PageRoot page={pc.PAGE_ACCESS_DENIED} />
    }
    return children;
  }

}

export default RoleRouter;
