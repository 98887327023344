import React, { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from 'react-router-dom';
import { Grid, TextField, Button, Typography } from '@mui/material';

import { doCreateUser } from "../../utils";

import mongoConfig from "../../mongo";

export const ConfirmInvitationForm = (props) => {
  const mongoUser = useSelector( (state) => state.auth.user );
  const dispatch = useDispatch();

  const { invitationData } = props;

  const [submitInProgress, setSubmitInProgress] = useState(false);
  const [userCreated, setUserCreated] = useState(false);
  const [displayName, setDisplayName] = useState(invitationData.displayName || '');
  const [newPassword, setNewPassword] = useState('');

  const handlePasswordChange = ({target}) => {
    setNewPassword(target.value);
  }
  const handleDisplayNameChange = ({target}) => {
    setDisplayName(target.value);
  }

  // const handleResetSubmit = (target) => {
  //   console.log(target.value);
  //   // target.preventDefault();
  //   // onResetSubmit(email);
  // }

  const handleCreateAccountSubmit = (target) => {
    target.preventDefault();
    setSubmitInProgress(true);

    // error handling:
    if ( invitationData && invitationData.username && displayName ==='' || newPassword === '' ) return;

    // normal processing
    const userDataRecord = {
      status: 'pending',
      timeZone: 'US/Central',
      displayName: displayName,
      role: invitationData.role,
      company: invitationData.company,
      username: invitationData.username,
    };

    if ( invitationData.groupsAccess && invitationData.groupsAccess.length > 0 ) {
      userDataRecord['groupsAccess'] = invitationData.groupsAccess;
    }

    if ( invitationData.officesAccess && invitationData.officesAccess.length > 0 ) {
      userDataRecord['officesAccess'] = invitationData.officesAccess;
    }

    mongoUser
      .mongoClient(mongoConfig.realmServiceName)
      .db(mongoConfig.databaseName)
      .collection('usersdata')
      .insertOne(userDataRecord)
      .then( () => {
        dispatch( doCreateUser({email: userDataRecord.username, password: newPassword}) );
        setSubmitInProgress(false);
        setUserCreated(true);
      }).catch((err) => {
        console.log('Create User Error: ' + err);
        setSubmitInProgress(false);
        setUserCreated(false);
      });
  }

  return (
    <form method="POST" onSubmit={handleCreateAccountSubmit}>
      { !userCreated &&
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
            <Grid item xs={12} sx={{textAlign: 'center'}}>
              <Typography>You are invited to join the {invitationData.company.toUpperCase()} team on Ring Analytics</Typography>
              <Typography>Please confirm your Full Name, enter the Password, and then create your new account.</Typography>
            </Grid>
              <Grid item xs={12}>
                <TextField value={invitationData.username} disabled required InputProps={{ readOnly: true, }} fullWidth label="Email" name="username" size="small" variant="outlined" />
              </Grid>
              <Grid item xs={12}>
                <TextField error={(!displayName || displayName === '') ? true : false} onChange={handleDisplayNameChange} value={displayName} required fullWidth label="Full Name" name="displayName" size="small" variant="outlined" />
              </Grid>
              <Grid item xs={12}>
                <TextField error={(!newPassword || newPassword === '') ? true : false} onChange={handlePasswordChange} required fullWidth label="Your Password" name="password" size="small" type="password" variant="outlined"/>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Button
              disabled={ (!displayName || displayName === '' || !newPassword || newPassword === '' || submitInProgress)}
              color="secondary" fullWidth type="submit" variant="contained"
            >
              { submitInProgress ? 'Creating User. Please wait' : 'Confirm and Create Account'}
            </Button>
          </Grid>
          <Grid item justifyContent="center" alignContent="center" alignItems="center" container xs={12}>
            <NavLink to="/login">
              I already have account, Go to Login Screen
            </NavLink>
          </Grid>
        </Grid>
      }
      { userCreated &&
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{textAlign: 'center'}}>
                <Typography>Please check your email to complete registration</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
    </form>
  );
};
