import { appConstants as ac } from '../constants';

// this is app state
const initialState = {
  somethingIsLoading: false, // to display overall app loading progress bar
  graphIsLoading: false,
  hotHoursIsLoading: false,
  tableIsLoading: false,
  dialog: {
    inProgress: false,
    success: false,
    error: false,
  },
  filterOptions: {
    searchOfficesOnly: null,
    selectedOfficeStatus: 'all',
    selectedGroup: null,
    selectedOffice: null,
    selectedNumber: 'all',
  }
};

const app = (state = initialState, action) => {
  switch (action.type) {
    case ac.APP_DIALOG_RESET:
      return {
        ...state,
        dialog: {
          inProgress: false,
          success: false,
          error: false,
        },
      };
    case ac.APP_DIALOG_IN_PROGRESS:
      return {
        ...state,
        dialog: {
          inProgress: true,
          success: false,
          error: false,
        },
      };
    case ac.APP_DIALOG_SUCCESS:
      return {
        ...state,
        dialog: {
          inProgress: false,
          success: true,
          error: false,
        },
      };
    case ac.APP_DIALOG_ERROR:
      return {
        ...state,
        dialog: {
          inProgress: false,
          success: false,
          error: action.error,
        },
      };
    case ac.APP_SOMETHING_IS_LOADING:
      return {
        ...state,
        somethingIsLoading: true,
      };
    case ac.APP_ALL_LOADED:
      return {
        ...state,
        somethingIsLoading: false,
      };
    case ac.APP_GRAPH_IS_LOADING:
      return ( action.loadingKey )
      ? {
          ...state,
          [action.loadingKey]: true,
        }
      : {
          ...state,
          graphIsLoading: true,
        };
    case ac.APP_GRAPH_LOADED:
      return ( action.loadingKey )
      ? {
          ...state,
          [action.loadingKey]: false,
        }
      : {
          ...state,
          graphIsLoading: false,
        };
    case ac.APP_HOTHOURS_IS_LOADING:
      return {
        ...state,
        hotHoursIsLoading: true,
      };
    case ac.APP_HOTHOURS_LOADED:
      return {
        ...state,
        hotHoursIsLoading: false,
      };
    case ac.APP_TABLE_IS_LOADING:
      return {
        ...state,
        tableIsLoading: true,
      };
    case ac.APP_TABLE_LOADED:
      return {
        ...state,
        tableIsLoading: false,
      };
    case ac.APP_FILTER_RESET: {
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          // searchOfficesOnly: null,
          selectedOfficeStatus: 'all',
          selectedGroup: null,
          selectedOffice: null,
          selectedNumber: 'all',
        }
      };
    }

    case ac.APP_SET_SEARCH_OFFICES_ONLY:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          searchOfficesOnly: action.searchOfficesOnly,
        }
      };
    case ac.APP_SET_FILTER_OFFICE_STATUS:
      return {
        ...state,
        filterOptions: {
          selectedOfficeStatus: action.selectedOfficeStatus,
          searchOfficesOnly: null,
          selectedGroup: null,
          selectedOffice: null,
          selectedNumber: 'all',
        }
      };
    case ac.APP_SET_FILTER_GROUP:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          selectedGroup: action.selectedGroup,
          selectedOffice: null,
          selectedNumber: 'all',
        }
      };
    case ac.APP_SET_FILTER_OFFICE:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          selectedOffice: action.selectedOffice,
          selectedNumber: 'all',
        }
      };
    case ac.APP_SET_FILTER_NUMBER:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          selectedNumber: action.selectedNumber,
        }
      };
    default:
      return state;
  }
};

export default app;
