import { authConstants as ac } from '../constants';

export const authSuccess = user => {
  return {
    type: ac.AUTH_SUCCESS,
    user
  };
};

export const authFail = error => {
  return {
    type: ac.AUTH_FAIL,
    error
  };
};

export const authMessage = message => {
  return {
    type: ac.AUTH_MESSAGE,
    message
  };
};

export const authInProgress = () => {
  return {
    type: ac.AUTH_INPROGRESS
  };
};

export const authRefresh = () => {
  return {
    type: ac.AUTH_REFRESH
  };
};
