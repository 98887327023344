import { dbConstants as dc } from '../constants';

// this is db state
const initialState = {
  mongo: null,
  callsCollection: null,
  callsUpdatedLately: 0,
  latestCallsOperation: null,
};

const db = (state = initialState, action) => {
  switch (action.type) {
    case dc.DB_CONNECTED:
      return {
        ...state,
        mongo: action.mongo,
        callsCollection: action.calls,
      };
    case dc.DB_FAILED:
      return {
        ...state,
        mongo: null,
        callsCollection: null,
        latestCallsOperation: null,
      };
    // case dc.DB_CALLS_COUNTER_RESET:
    //   return {
    //     ...state,
    //     callsUpdatedLately: 0,
    //   };
    case dc.DB_CALLS_UPDATED:

      return {
        ...state,
        callsUpdatedLately: state.callsUpdatedLately + 1,
        latestCallsOperation: action.theUpdate,
      };
    default:
      return state;
  }
};

export default db;
