import React, { Suspense }  from 'react';
import { Box } from '@mui/material';

// import CallsPageTemplate from '../../components/Calls/CallsPageTemplate';
const CallsPageTemplate = React.lazy(() => import('../../components/Calls/CallsPageTemplate'));
import PageTitle from '../../components/Common/PageTitle';

export const OtherCallsPage = (props) => {

  return (
    <Box>
      <PageTitle thepage="/calls/other" />
      <Suspense fallback={<span>...</span>}>
        <CallsPageTemplate
          props={props}
          callsSection='other'
          lineChartsHeading='Service Calls'
          heatmapColors='greys'
          showTags={true}
          showMultiChart={true}
        />
      </Suspense>
    </Box>
  );
};
