import React, { useState, useEffect } from 'react';
import * as Realm from "realm-web";
import ObjectID from 'bson-objectid';

import makeStyles from '@mui/styles/makeStyles';

import { Container, Box, Grid } from '@mui/material';

import { ConfirmInvitationForm } from "../components/Auth/ConfirmInvitationForm";
import { InRefreshPage } from './InRefreshPage';

const useStyles = makeStyles(() => ({
  logo: {
    maxWidth: '100%',
    display: 'flex',
    margin: '2em auto'
  }
}));

import mongoConfig from '../mongo';
// import { doConfirmUserEmail } from "../utils";

export const EmailedInvitationPage = (props) => {
  const classes = useStyles();

  const [anonymousUser, setAnonymousUser] = useState(undefined);
  const [invitationObj, setInvitationObj] = useState(undefined);
  const [invitationID, setInvitationID] = useState(null);
  //
  // useEffect(() => {
  //   if ( token !== null && tokenId !== null) {
  //     doConfirmUserEmail(token, tokenId).then((result) => {
  //       setWasConfirmed(result);
  //     });
  //   }
  // }, [token, tokenId]);

  // get invitation id from url params
  useEffect(() => {
    if ( props.invitationid ) {
      setInvitationID(props.invitationid);
    }
  }, [props.invitationid] );


  // preload invitation information as anonymous user
  useEffect(() => {
    if ( invitationID && invitationID !== null ) {

      try {
        const currentApp = Realm.App.getApp(mongoConfig.realmAppId);
        const anonymousCredentials = Realm.Credentials.anonymous();

        currentApp.logIn(anonymousCredentials).then(anonymousUser => {
          setAnonymousUser(anonymousUser);
        }, reason => {
          console.log('anonymous login failed');
          console.log(reason);
        });

      } catch (err) {
        console.error('Failed to log in as anonymous', err);
      }

    }
  }, [invitationID] );


  // using anonymous user load invitation data
  useEffect(() => {
    if ( anonymousUser ) {
      console.log('find');
      console.log(invitationID);

      anonymousUser
        .mongoClient(mongoConfig.realmServiceName)
        .db(mongoConfig.databaseName)
        .collection('usersinvitations')
        .findOne(
          { _id: ObjectID(invitationID) },
        )
        .then( invitationData => {
          setInvitationObj(invitationData);
        });
    }
  }, [anonymousUser, invitationID] );

  return ( invitationObj === undefined ) ?
    (
      <InRefreshPage />
    ) : (
      <Container maxWidth="xs">

        <Box my={4}>
          <Box>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <img className={classes.logo} src="/img/ringanalytics-brand.png" alt="Ring Analytics" title="Ring Analytics"/>
              </Grid>
            </Grid>
            { (invitationObj !== null) && <ConfirmInvitationForm invitationData={invitationObj} /> }
          </Box>
        </Box>
        <Box display="flex" height={1} >

        </Box>
      </Container>
    );
};
